import { Controller } from '@hotwired/stimulus'
import { stimulus } from '~/init'

export default class ModalController extends Controller {
  connect () {
    const options = {
      placement: 'center-center',
      onHide: () => {
        this.element.remove()
        // window.modal = undefined
      }
    }

    const instanceOptions = {
      id: 'modalContainer',
      override: false
    }

    this.modal = new window.Modal(this.element, options, instanceOptions)
    this.modal.show()
  }

  close () {
    this.modal.hide()
  }

  disconnect () {
    // this.element.remove()
    try {
      this.modal.hide()
    } catch (error) {
      // do nothing
    }

    const backdropElement = document.body.querySelector('[modal-backdrop]')
    if (backdropElement) {
      backdropElement.remove()
    }
  }
}

stimulus.register('modal', ModalController)
