import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    field: { type: String, default: 'barcode_data' },
    facilityId: { type: Number, default: null },
    workType: { type: String, default: null },
    serviceType: { type: String, default: null },
    newWork: { type: Boolean, default: false },
    url: { type: String, default: '/reporting/inventories/find' }
  }

  connect () {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  static targets = ['input', 'searchBtn']

  async find (event) {
    this.inputTarget.focus()

    return await post(this.urlValue, {
      body: this.#body(),
      responseKind: 'turbo-stream'
    })
  }

  hideSearch (event) {
    setTimeout(() => {
      this.searchBtnTarget.classList.add(this.toggleClass)
    }, 150)
  }

  showSearch (event) {
    this.searchBtnTarget.classList.remove(this.toggleClass)
  }

  #body () {
    let data = {
      barcode: this.inputTarget.value,
      field: this.fieldValue,
      work_type: this.workTypeValue,
      service_type: this.serviceTypeValue,
      new_work: this.newWorkValue
    }

    data = this.facilityIdValue ? { ...data, facility_id: this.facilityIdValue } : data

    return data
  }
}
