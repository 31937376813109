import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

// import { LicenseManager, CoreModule, CaptureVisionRouter, CameraView, CameraEnhancer, MultiFrameResultCrossFilter } from 'dynamsoft-barcode-reader-bundle'

export default class extends Controller {
  static values = {
    field: { type: String, default: 'barcode_data' },
    facilityId: { type: Number, default: null },
    workType: { type: String, default: null },
    serviceType: { type: String, default: null },
    newWork: { type: Boolean, default: false },
    url: { type: String, default: '/reporting/inventories/find' }
  }

  static targets = ['cameraAccess']

  initialize () {
    window.Dynamsoft.License.LicenseManager.initLicense
    ('DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAzNDE3Njc2LVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMzQxNzY3NiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NkbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJjaGVja0NvZGUiOjE0MDgwNDE0Nn0=')
    window.Dynamsoft.Core.CoreModule.loadWasm(['dbr'])
  }

  async connect () {
    this.cvRouter = await window.Dynamsoft.CVR.CaptureVisionRouter.createInstance()
    this.cameraView = await window.Dynamsoft.DCE.CameraView.createInstance()
    this.cameraEnhancer = await window.Dynamsoft.DCE.CameraEnhancer.createInstance(this.cameraView)

    this.#initScanner()
  }

  disconnect () {
    try {
      if (this.cameraEnhancer) {
        this.cameraEnhancer.close()
      }
      if (this.cvRouter) {
        this.cvRouter.stopCapturing()
      }
      if (this.cameraView) {
        this.cameraView.UIElement.remove()
      }
    } catch (error) {
      console.error('Error disconnecting Dynamsoft:', error)
    }
  }

  async #initScanner () {
    this.cameraEnhancer.setScanRegion({
      x: 10,
      y: 25,
      width: 80,
      height: 50,
      isMeasuredInPercentage: true
    })

    this.element.append(this.cameraView.UIElement)
    this.cameraView.setScanLaserVisible(true)
    this.cvRouter.setInput(this.cameraEnhancer)

    this.cvRouter.addResultReceiver({
      onDecodedBarcodesReceived: (result) => {
        if (result.barcodeResultItems.length > 0) {
          window.Dynamsoft.DCE.Feedback.beep()
          this.#onDetect({ codeResult: result.barcodeResultItems })
        }
      }
    })

    const filter = new window.Dynamsoft.Utility.MultiFrameResultCrossFilter()
    filter.setDuplicateForgetTime(5000)
    filter.enableResultCrossVerification('barcode', true)
    filter.enableResultDeduplication('barcode', true)
    await this.cvRouter.addResultFilter(filter)

    await this.cameraEnhancer.open()
    await this.cvRouter.startCapturing('ReadSingleBarcode')
  }

  #onDetect (result) {
    this.results = this.results || []

    if (!result.codeResult) {
      console.error('Failed to read barcode')
      return
    }

    const { text } = result.codeResult[0]
    this.#post(text)
  }

  #post (code = '') {
    post(this.urlValue, {
      body: this.#body(code),
      responseKind: 'turbo-stream'
    })
  }

  #body (code) {
    let data = {
      barcode: code,
      field: this.fieldValue,
      work_type: this.workTypeValue,
      service_type: this.serviceTypeValue,
      new_work: this.newWorkValue
    }

    data = this.facilityIdValue ? { ...data, facility_id: this.facilityIdValue } : data

    return data
  }
}
