import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

export function uppyInstance ({ presignUrl, autoProceed = true, allowedFileTypes = ['image/*'], minNumberOfFiles = 0, maxNumberOfFiles = 3, maxFileSize = 20000000 }) {
  const uppy = new Uppy({
    autoProceed,
    restrictions: {
      allowedFileTypes,
      minNumberOfFiles,
      maxNumberOfFiles,
      maxFileSize
    }
  })

  uppy.use(AwsS3, {
    companionUrl: presignUrl
  })

  return uppy
}
