import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fieldset', 'trigger']

  connect () {
    if (this.hasTriggerTarget) {
      setTimeout(() => {
        document.activeElement.blur()
      }, 250)
    }
  }

  confirm (event) {
    this.#customConfirm('These changes will update the inventory record and all the associated work orders. Are you sure you want to make these changes?')
      .then((confirmed) => {
        if (confirmed) {
          this.#enable()
        } else {
          event.preventDefault()
          event.stopImmediatePropagation()
        }
      })
  };

  #enable () {
    this.fieldsetTarget.dataset.readonly = false
    this.triggerTarget.remove()
  }

  #customConfirm (message) {
    const dialog = document.getElementById('turbo-confirm')
    dialog.querySelector('h3').textContent = message
    dialog.showModal()

    return new Promise((resolve) => {
      // Handle dialog close event
      dialog.addEventListener('close', () => {
        resolve(dialog.returnValue === 'confirm')
      }, { once: true })

      // Close the dialog when clicking outside of it (optional)
      dialog.addEventListener('click', (event) => {
        if (event.target.nodeName === 'DIALOG') {
          dialog.returnValue = 'cancel'
          dialog.close()
        }
      })
    })
  }
}
