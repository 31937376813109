import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.querySaveId = this.element.dataset.querySaveId
    this.saveQueryParamsOnLoad()
    this.loadQueryParams()
  }

  getStorageKey (querySaveId = this.querySaveId) {
    return `savedQuery_${querySaveId}`
  }

  getStoredQuery (querySaveId = this.querySaveId) {
    const storedData = localStorage.getItem(this.getStorageKey(querySaveId))
    if (!storedData) return null

    const { queryString, timestamp } = JSON.parse(storedData)
    const currentTime = new Date().getTime()
    const sevenDays = 7 * 24 * 60 * 60 * 1000

    if (currentTime - timestamp > sevenDays) {
      this.removeStoredQuery(querySaveId)
      return null
    }

    return queryString
  }

  setStoredQuery (queryString, querySaveId = this.querySaveId) {
    const currentTime = new Date().getTime()
    const data = { queryString, timestamp: currentTime }
    localStorage.setItem(this.getStorageKey(querySaveId), JSON.stringify(data))
  }

  removeStoredQuery (querySaveId = this.querySaveId) {
    localStorage.removeItem(this.getStorageKey(querySaveId))
  }

  saveQueryParamsOnLoad () {
    const queryString = window.location.search

    if (queryString.length <= 1) return

    this.setStoredQuery(queryString)
  }

  loadQueryParams () {
    const storedQuery = this.getStoredQuery()

    if (storedQuery && window.location.search.length <= 1) {
      window.location.assign(window.location.pathname + storedQuery)
    }
  }

  prefillLink (event) {
    const queryId = event.currentTarget.dataset.queryId
    const storedQuery = this.getStoredQuery(queryId)

    if (storedQuery) {
      const url = new URL(event.currentTarget.href)
      const storedParams = new URLSearchParams(storedQuery)

      for (const [key, value] of storedParams.entries()) {
        if (!url.searchParams.has(key)) {
          url.searchParams.append(key, value)
        }
      }

      event.currentTarget.href = url.toString()
    }
  }

  clearStorage (event) {
    event.preventDefault()
    this.removeStoredQuery()
    window.location.href = event.currentTarget.href
  }
}
