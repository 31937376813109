import { Controller } from '@hotwired/stimulus'
import Imask from 'imask'

export default class ImaskController extends Controller {
  static values = { type: String }

  initialize () {
    switch (this.element.dataset.type) {
      case 'time':
        Imask(this.element, {
          overwrite: true,
          autofix: true,
          mask: 'HH:MM',
          blocks: {
            HH: {
              mask: Imask.MaskedRange,
              from: 0,
              to: 24,
              maxLength: 2
            },
            MM: {
              mask: Imask.MaskedRange,
              from: 0,
              to: 59,
              maxLength: 2
            }
          }
        })
        break

      case 'phone':
        Imask(this.element, {
          mask: '+{1} (000) 000-0000'
        })
        break
    }
  }
}
