import { Controller } from '@hotwired/stimulus'
// import Dashboard from '@uppy/dashboard'
import Informer from '@uppy/informer'
import ProgressBar from '@uppy/status-bar'
import DragDrop from '@uppy/drag-drop'

import { post } from '@rails/request.js'

import { uppyInstance } from './uppy/core'

export default class extends Controller {
  static targets = ['input']
  static values = {
    presignUrl: { type: String, default: '/reporting/work_images' },
    postUrl: { type: String },
    source: { type: String, default: 'general' }
  }

  connect () {
    this.#setup()
    document.addEventListener('turbo:morph', this.#setup.bind(this))
  }

  disconnect () {
    window.uppy = null
    this.uppy.close()
    document.removeEventListener('turbo:morph', this.#setup.bind(this))
  }

  #setup () {
    this.uppy = uppyInstance({
      presignUrl: this.presignUrlValue,
      postUrl: this.postUrlValue
    })
      .use(DragDrop, {
        target: this.element,
        height: 'auto',
        locale: {
          strings: {
            dropHereOr: 'Drop here or %{browse}',
            browse: 'browse'
          }
        }
      })
      .use(ProgressBar, { target: this.element })
      .use(Informer, { target: this.element })
      .on('upload-success', async (file, res) => {
        const uploadedFileData = JSON.stringify({
          id: file.meta.key.match(/^cache\/(.+)/)[1],
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type
          }
        })

        const { response } = await post(this.postUrlValue, {
          body: {
            image: {
              asset: uploadedFileData,
              source: this.sourceValue
            }
          },
          responseKind: 'turbo-stream'
        })

        if (response.status === 200) {
          this.uppy.removeFile(file.id)
        }
      })

    window.uppy = this.uppy // useful for testing

    return this.uppy
  }
}
