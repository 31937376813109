import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select', 'container']

  static values = {
    url: String,
    param: String,
    notNewItem: Boolean,
    additionalParams: Object
  }

  connect () {
    if (this.selectTarget.id === '') {
      this.selectTarget.id = Math.random().toString(36)
    }
  }

  change (event) {
    const params = new URLSearchParams()

    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append('target', this.selectTarget.id)
    params.append('container', this.containerTarget.id)
    params.append('not_new_item', this.notNewItemValue)

    if (this.hasAdditionalParamsValue) {
      Object.entries(this.additionalParamsValue).forEach(([key, value]) => {
        params.append(key, value)
      })
    }

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    })
  }
}
