import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  connect () {
    this.urlPart = this.linkTarget.dataset.urlpart
    this.regex = new RegExp(`/${this.urlPart}/(\\d*)`)
    this.#hideLink()
  }

  setLink (event) {
    const currentUrl = this.linkTarget.href
    const newValue = event.target.value

    // Replace the existing ID in the URL with the new value
    let newUrl = currentUrl.replace(this.regex, `/${this.urlPart}/${newValue}`)
    newUrl = this.#ensureIdInUrl(newUrl)
    this.linkTarget.href = newUrl

    this.#hideLink()
  }

  #hideLink () {
    const match = this.linkTarget.href.match(this.regex)

    // Hide the link if the ID is '0' or empty, or if no match is found
    if ((match && (match[1] === '0' || match[1] === '')) || !match) {
      this.linkTarget.style.display = 'none'
    } else {
      this.linkTarget.style.display = 'block'
    }
  }

  #ensureIdInUrl (url) {
    // Ensure that the URL contains an ID between the slashes
    const regex = new RegExp(`/${this.urlPart}//`)
    if (regex.test(url)) {
      return url.replace(regex, `/${this.urlPart}/0/`)
    }
    return url
  }
}
